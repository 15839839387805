import(/* webpackMode: "eager" */ "/codebuild/output/src3900892892/src/helloero-new/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3900892892/src/helloero-new/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["MenuButton"] */ "/codebuild/output/src3900892892/src/helloero-new/packages/shared/components/layouts/HelloEroLayout/Header/MenuButton/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["UserName"] */ "/codebuild/output/src3900892892/src/helloero-new/packages/shared/components/layouts/HelloEroLayout/Header/UserName/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OverlayMenus"] */ "/codebuild/output/src3900892892/src/helloero-new/packages/shared/components/layouts/HelloEroLayout/OverlayMenus/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src3900892892/src/helloero-new/packages/shared/components/layouts/VersionBanner.tsx");
